import { Button, Modal, Text } from '@patternfly/react-core';
import './DeleteConfirmationModal.css';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
	titleText?: string;
	textLabel?: string;
	cancelLabel?: string;
	confirmLabel?: string;
};

function DeleteConfirmationModal(props: Props) {
	return (
		<Modal
			title={props.titleText ?? 'Delete Confirmation'}
			variant="small"
			isOpen={props.isOpen}
			onClose={props.onClose}
			className="delete-modal"
			actions={[
				<div className="float-right">
					<Button
						variant="secondary"
						onClick={props.onClose}
						className="nudge-right-btn"
					>
						{props.cancelLabel ?? 'Cancel'}
					</Button>

					<Button
						variant="primary"
						onClick={props.onSubmit}
					>
						{props.confirmLabel ?? 'Delete'}
					</Button>
				</div>,
			]}
		>
			<hr />
			<br />
			<div className="text-center">
				{' '}
				<Text component="h3">
					{props.textLabel ??
						'Are you sure you would like to proceed with deleting this item?'}
				</Text>
			</div>
		</Modal>
	);
}

export default DeleteConfirmationModal;
