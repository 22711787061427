import React, { ReactElement, useState } from 'react';
import './Administrators.css';
import { useOutletContext } from 'react-router-dom';
import PageTitleSubheader from '../../layout/subheader/PageTitleSubheader';
import { SubheaderContext } from '../../layout/Layout';
import { useMount } from 'react-use';
import { SystemUser, TSystemUser } from '../../api/systemUser/SystemUser';
import {
	Action,
	Column,
	Field,
	ISelectOption,
	timestampToMMDDYYYY,
	UIType,
} from '@zeroedin-tech/zi-common-ui/lib/';
import FilterTableLayout from '../../layout/FilterTableLayout';
import SchnurTable from '@zeroedin-tech/zi-common-ui/lib/components/SchnurTable/SchnurTable';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib/components/toast/ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { AlertVariant, Button, Modal, ModalVariant } from '@patternfly/react-core';
import SchnurForm from '@zeroedin-tech/zi-common-ui/lib/components/SchnurForm/SchnurForm';
export default function Administrators(): ReactElement {
	const { addToast } = useToast();
	const [setSubheader]: SubheaderContext = useOutletContext();
	const [tableData, setTableData] = useState<TSystemUser[]>([]);
	const [tableLoading, setTableLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [activeUser, setActiveUser] = useState<TSystemUser>(SystemUser.Default() as TSystemUser);
	const [isFormLoading, setIsFormLoading] = useState<boolean>(false);

	const selectedColumns: Column<TSystemUser>[] = [
		{
			title: 'Account',
			columnName: 'account',
		},
		{
			title: 'Name',
			columnName: 'name',
		},
		{
			title: 'Status',
			columnName: 'status',
		},
		{
			title: 'Last Login',
			columnName: 'last_login',
			customAccessor(row: TSystemUser): string {
				return row.last_login ? timestampToMMDDYYYY(row.last_login) : 'Never';
			},
		},
	];

	const actions: Action<TSystemUser>[] = [
		{
			name: (
				<>
					Edit <FontAwesomeIcon icon={faPenToSquare} />
				</>
			),
			callback: (item) => {
				setActiveUser(item);
				setIsModalOpen(true);
			},
		},
		{
			name: 'Delete',
			callback: (item) => {
				setTableLoading(true);

				SystemUser.Delete(item.id)
					.then(() => {
						setTableLoading(false);
						setTableData((prev) => prev.filter((user) => user.id !== item.id));
						addToast('System User deleted successfully.', AlertVariant.success);
					})
					.catch(() => {
						setTableLoading(false);
						addToast(
							'An error occurred while trying to delete System User. Please set their status to revoked until this can be resolved.',
							AlertVariant.danger
						);
					});
			},
		},
		{
			name: <>Request Password Reset</>,
			callback: (item) => {
				SystemUser.RequestResetPassword(item)
					.then(() => {
						addToast('Password reset email sent successfully.', AlertVariant.success);
					})
					.catch(() => {
						addToast(
							'An error occurred while trying to send password reset email. Please try again later.',
							AlertVariant.danger
						);
					});
			},
		},
		{
			name: <>Clear Password History</>,
			callback: (item) => {
				SystemUser.ClearPasswordHistory(item)
					.then(() => {
						addToast('Password history cleared successfully.', AlertVariant.success);
					})
					.catch(() => {
						addToast(
							'An error occurred while trying to clear password history. Please try again later.',
							AlertVariant.danger
						);
					});
			},
		},
	];

	const formProperties: Field<TSystemUser>[] = [
		{
			title: 'Account Name',
			columnName: 'account',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'A unique identifier that will be used to log in.',
			},
			required: true,
		},
		{
			title: 'User Name',
			columnName: 'name',
			uiSchema: {
				type: UIType.TEXT,
				helpText: 'The name of the account holder.',
			},
			required: true,
		},
		{
			title: 'Email',
			columnName: 'email',
			uiSchema: {
				type: UIType.TEXT,
				helpText:
					'The email of the account holder. This email will be used to set and their passwords.',
			},
		},
		{
			title: 'Account Status',
			columnName: 'status',
			uiSchema: {
				type: UIType.SELECT,
				options: ['Active', 'Revoked'],
				initialSelection: 'id' in activeUser ? activeUser.status : 'Active',
				onSelect: (value: ISelectOption) => {
					return value.key;
				},
			},
		},
	];

	useMount(() => {
		setSubheader(
			<PageTitleSubheader
				pageTitle="Administrators"
				pageDescription="Manage your administrators."
			/>
		);

		SystemUser.GetAll()
			.then((users) => {
				setTableData(users);
				setTableLoading(false);
			})
			.catch(() => {
				addToast(
					'An error occurred while trying to fetch System Users. Please try again later.',
					AlertVariant.danger
				);
			});
	});

	const addButton = (
		<Button
			data-testid={'systemUser-create'}
			variant={'primary'}
			onClick={() => {
				setActiveUser(SystemUser.Default() as TSystemUser);
				setIsModalOpen(true);
			}}
		>
			New System User
		</Button>
	);

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleSuccess = (value: TSystemUser) => {
		const index = tableData.findIndex((row) => row.id === value.id);

		if (index >= 0) {
			setTableData((prev) => [...prev.slice(0, index), value, ...prev.slice(index + 1)]);
		} else {
			setTableData((prev) => [...prev, value]);
		}

		setIsModalOpen(false);
	};

	const dimensionTable = (
		<SchnurTable<TSystemUser>
			ariaLabel={'System Users'}
			columns={selectedColumns}
			data={tableData}
			caption="System Users"
			actions={actions}
			loading={tableLoading}
		/>
	);

	return (
		<React.Fragment>
			<Modal
				variant={ModalVariant.medium}
				title="System User Management"
				isOpen={isModalOpen}
				onClose={handleClose}
			>
				<SchnurForm<TSystemUser>
					title={'System User Management'}
					fields={formProperties}
					initialSubject={activeUser}
					isLoading={isFormLoading}
					onSubmit={(user) => {
						setIsFormLoading(true);

						if (user.id) {
							SystemUser.Update(user)
								.then((updated) => {
									handleSuccess(updated);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the System User. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						} else {
							SystemUser.New(user)
								.then((newDim) => {
									handleSuccess(newDim);
								})
								.catch(() => {
									addToast(
										'An error occurred while trying to save the System User. Please try again later.',
										AlertVariant.danger
									);
								})
								.finally(() => {
									setIsFormLoading(false);
								});
						}
					}}
				/>
			</Modal>
			<React.Fragment>
				<FilterTableLayout
					table={dimensionTable}
					layoutActions={addButton}
				/>
			</React.Fragment>
		</React.Fragment>
	);
}
